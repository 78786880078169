<template>
  <div>
    <div class="flex gap-8" style="margin-bottom: 0.1rem;">
      <a-select
        placeholder="全部设备"
        :options="deviceOption"
        v-model="params.deviceId"
        @change="selectChange"
        style="width: 2rem;"
        allowClear
      />
      <a-select
        style="width: 2rem;"
        @change="selectChange"
        placeholder="全部传感器"
        :options="monitoringOption"
        v-model="params.secondaryDeviceId"
        allowClear
      ></a-select>

      <a-range-picker @change="datePickerChange" format="YYYY-MM-DD" />
    </div>
    <div style="height: 50vh">
      <PaginationTable
        ref="tableRef"
        row-key="pkId"
        :api="api"
        :columns="columns"
        :scroll="{ x: false, y: 'max-content' }"
        :paginationConfig="{
          size: 'small',
          showQuickJumper: false,
        }"
      />
    </div>
  </div>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import EmptyContent from '@/components/empty-content/empty-content.vue';
import AsyncSelect from '@/components/async-select';
import PaginationTable from '@/components/pagination-table';
import { nowSize } from '@/utils/common';
import moment from 'moment';
import { LayoutSelect } from '@/views/screen/layout';

import {
  deviceStatusEnum,
  onOffEnum,
} from '@/views/safety-device/edge-protection/enum';
import { getDeviceLists } from '@/services/screen/jiangsu/vehicle';
import { deviceTypeEnum } from '@/enum/device';
import { pageTypeEnum } from '@/views/safety-device/component/safety-utils';
import {
  getPitList,
  getPitMonitoringType,
} from '@/services/screen/jiangsu/pit';

@Component({
  components: { EmptyContent, AsyncSelect, PaginationTable, LayoutSelect },
})
export default class DataListModal extends Vue {
  @Prop({ required: true }) projectId;

  deviceGroup = deviceTypeEnum[pageTypeEnum.pitMonitor].deviceGroup;
  params = {
    deviceId: undefined,
    startTime: undefined,
    endTime: undefined,
    secondaryDeviceId: undefined,
  };

  get deviceStatusOption() {
    return Object.entries(deviceStatusEnum).map(([value, label]) => ({
      label,
      value,
    }));
  }

  get onOffOption() {
    return Object.entries(onOffEnum).map(([value, label]) => ({
      label,
      value,
    }));
  }

  deviceList = [];
  get deviceOption() {
    return this.deviceList.map(item => ({
      label: item.deviceName,
      value: item.pkId,
    }));
  }
  async getDeviceList() {
    this.deviceList = await getDeviceLists({
      deviceGroup: this.deviceGroup.toLocaleUpperCase(),
      idxProjectId: this.projectId,
    });
  }

  // 传感器
  monitoringList = [];
  get monitoringOption() {
    return this.monitoringList.map(item => ({
      label: item.text,
      value: item.value,
    }));
  }
  async getMonitoringList() {
    this.monitoringList = await getPitMonitoringType(this.projectId);
  }
  async api({ current, size }) {
    const res = await getPitList({
      current,
      size,
      idxProjectId: this.projectId,
      ...this.params,
    });
    return res;
  }
  columns = [
    {
      title: '设备名称',
      dataIndex: 'deviceName',
      width: nowSize(160),
    },
    {
      title: '设备码',
      dataIndex: 'deviceCode',
      width: nowSize(150),
    },
    {
      title: '传感器',
      dataIndex: 'secondaryDeviceId',
      width: nowSize(150),
    },
    {
      title: '监测类型',
      dataIndex: 'typeName',
      width: nowSize(100),
    },
    {
      title: '监测数据',
      dataIndex: 'value',
      width: nowSize(100),
    },
    {
      title: '上次监测数据',
      dataIndex: 'lastValue',
      width: nowSize(120),
    },
    {
      title: '单次变化量',
      dataIndex: 'singleValueOfChange',
      width: nowSize(100),
    },
    {
      title: '变化速率',
      dataIndex: 'rateOfChange',
      width: nowSize(100),
    },
    {
      title: '报警状态',
      dataIndex: 'isAlarm',
      width: nowSize(100),
      customRender: text => (text ? '报警' : '正常'),
    },
    {
      title: '上报时间',
      dataIndex: 'createdTime',
      width: nowSize(200),
      customRender: text => moment(text).format('YYYY-MM-DD HH:mm:ss'),
    },
  ];

  selectChange() {
    this.$refs.tableRef.search();
  }

  datePickerChange([start, end]) {
    this.params.startTime = start
      ? moment(start)
          .startOf('day')
          .valueOf()
      : start;
    this.params.endTime = end
      ? moment(end)
          .endOf('day')
          .valueOf()
      : end;

    this.$refs.tableRef.search();
  }

  created() {
    this.getDeviceList();
    this.getMonitoringList();
  }
}
</script>

<style lang="less" module></style>
