<template>
  <div class="grid grid-cols-3 h-full" :class="$style.dataWrap">
    <div
      class="flex items-center justify-center"
      v-for="(item, index) in list"
      :key="index"
    >
      <div
        class="flex flex-col items-center justify-center"
        :class="$style.item"
      >
        <span :class="$style.textShadow">{{ item.value }}次</span>
        <div :class="$style.icon"></div>
        <span :class="$style.name">{{ item.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getPitData } from '@/services/screen/jiangsu/pit';
import { Emitter } from '@/views/screen/components/screen-utils';
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class PitDataView extends Vue {
  get list() {
    return [
      {
        name: '水平数据',
        value: this.format(this.data.horizontalCount),
        unit: '次',
      },
      {
        name: '垂直沉降数据',
        value: this.format(this.data.verticalSettlementCount),
        unit: '次',
      },
      {
        name: '水位数据',
        value: this.format(this.data.waterLevelCount),
        unit: '次',
      },
      {
        name: '位移数据',
        value: this.format(this.data.verticalDisplacementCount),
        unit: '次',
      },
      {
        name: '倾斜数据',
        value: this.format(this.data.poleInclinationtCount),
        unit: '次',
      },
      {
        name: '裂缝数据',
        value: this.format(this.data.crackMeterCount),
        unit: '次',
      },
    ];
  }

  data = {};

  format(val) {
    return val > 0 ? val : '-';
  }

  async deviceChange(deviceId) {
    this.data = (await getPitData(deviceId)) ?? {};
  }

  showDataList() {
    this.$emit('showDetail');
  }

  created() {
    Emitter.$on('deviceChange', this.deviceChange);
  }

  beforeDestroy() {
    Emitter.$off('deviceChange', this.deviceChange);
  }
}
</script>

<style lang="less" module>
.icon {
  background-image: url('~@/assets/images/theme/labor-icon.png');
  background-size: cover;
  width: 0.58rem;
  height: 0.14rem;
}
.textShadow {
  text-shadow: 0px 0px 7px #1f69c8, 0px 0px 10px #7db6ff;
  color: #fff;
  font-size: 0.24rem;
}
.item {
  width: 0.97rem;
  height: 0.88rem;
  background: linear-gradient(
    270deg,
    rgba(76, 175, 255, 0) 0%,
    rgba(76, 175, 255, 0.1) 52%,
    rgba(76, 175, 255, 0) 100%
  );
  .name {
    color: #d8e1f3;
    font-size: 0.12rem;
    padding-top: 0.1rem;
  }
}
.dataWrap {
  padding: 0.3rem 0.2rem;
  position: relative;
  .detail {
    position: absolute;
    top: 0.08rem;
    right: 0.08rem;
    color: #37d7db;
    font-size: 0.14rem;
    cursor: pointer;
    user-select: none;
  }
}
</style>
