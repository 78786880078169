// 临边防护设备状态
export const deviceStatusEnum = {
  1: '正常',
  2: '缺失/移动',
  3: '人体接近',
};

export const alarmEnum = {
  1: '正常',
  2: '报警',
};

export const onOffEnum = {
  1: '开机',
  2: '关机',
};

export const underVoltageEnum = {
  1: '正常',
  2: '欠压',
};
