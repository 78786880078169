<template>
  <div :class="$style.statusWrap" class="h-full flex flex-col items-center">
    <div
      class="w-full flex items-center justify-between"
      :class="$style.wrapHeader"
    >
      <div class="flex">
        <span :class="[$style.endTime]">最后更新时间：2022-10-10 12:00:00</span>
      </div>
      <span :class="$style.deviceId">设备码：XXXXXXXXXXXXXX</span>
    </div>
    <img
      :class="$style.unloadImg"
      src="@/assets/images/screen/unloading-img.svg"
    />

    <div class="flex flex-col gap-y-rem-16" :class="[$style.floatBox]">
      <div class="">设备信息</div>
      <div class="">司机信息</div>
      <div class="">上下机记录</div>
    </div>

    <div :class="$style.timeText">累计安全运行时间：X天11小时</div>
    <div
      class="grid grid-cols-5 gap-x-rem-16 gap-y-rem-16"
      :class="$style.indicatorWrap"
    >
      <div
        :class="$style.indicator"
        class="flex justify-between items-center"
        v-for="(item, index) in indicator"
        :key="index"
      >
        <div :class="$style.leftLabel">
          <AliIcon :type="item.icon" :class="$style.iconStyle" />
          <div :class="[$style.text]">{{ item.text }}</div>
        </div>
        <div :class="[$style.textValue]" v-if="item.unit">
          <span>{{ item.count ?? '8' }}</span>
          <span :class="[$style.unit]">{{ item.unit }}</span>
        </div>
        <div :class="[$style.textStatus]" v-else>
          <span>{{ item.count ?? '正常' }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';
import AliIcon from '@/components/ali-icon';

@Component({ components: { AliIcon } })
export default class PitStatus extends Vue {
  indicator = {
    height: {
      text: '料台重量',
      icon: 'tc-icon-zaizhongliang',
      count: null,
      unit: this.$t('unit.ton'),
    },
    margin: {
      text: this.$t('detail.height'),
      icon: 'tc-icon-gaodu',
      count: null,
      unit: this.$t('unit.meter'),
    },
    ratedLiftingWeight: {
      text: '速度',
      icon: 'tc-icon-yunhangsudu',
      count: null,
      unit: this.$t('unit.meter') + '/' + this.$t('unit.second'),
    },
    liftingWeight: {
      text: '倾角',
      icon: 'tc-icon-qingjiao',
      count: null,
      unit: this.$t('safety.limit'),
    },
    rotationAngle: {
      text: '重量状态',
      icon: 'tc-icon-zaizhongliang',
      count: null,
    },
    torquePercentage: {
      text: '速度状态',
      icon: 'tc-icon-yunhangsudu',
      count: null,
    },
    camber: {
      text: '倾角状态',
      icon: 'tc-icon-qingjiao',
      count: null,
    },
  };
}
</script>

<style lang="less" module>
@import '@/assets/css/mixin.less';
.statusWrap {
  .px2rem(padding, 16);
  position: relative;
  user-select: none;
  .unloadImg {
    .px2rem(height, 400);
    .px2rem(width, 800);
    margin-top: 0.4rem;
  }
  .floatBox {
    position: absolute;
    right: 0;
    top: 0.72rem;
    div {
      writing-mode: vertical-lr;
      letter-spacing: 5px;
      padding: 0.13rem 0.08rem 0.08rem 0.08rem;
      background: #223142;
      cursor: pointer;
      border-radius: 0.08rem 0 0 0.08rem;
      font-size: 0.12rem;
    }
  }
}
.indicator {
  width: 1.43rem;
  height: 0.56rem;
  padding: 0.08rem;
  background: url('~@/assets/images/theme/card2-bg.svg') center / 100% 100%;
}
.indicatorWrap {
  margin-top: 0.38rem;
}
.timeText {
  margin-top: 0.18rem;
  color: #9ac0d9ff;
  font-size: 0.12rem;
}
.wrapHeader {
  // margin-bottom: auto;
  height: 0.32rem;
}
.textValue,
.textStatus {
  text-shadow: 0px 0px 7px #1f69c8, 0px 0px 10px #7db6ff;
  font-size: 0.16rem;
  color: #fff;
}

.textValue {
  font-size: 0.24rem;
  .unit {
    font-size: 0.12rem;
  }
}
.iconStyle {
  font-size: 0.24rem;
  color: #fff;
}
.leftLabel {
  display: flex;
  align-items: center;
  font-size: 0.14rem;
  color: #9ac0d9;
  .text {
    width: 0.36rem;
    line-height: 1.2;
    letter-spacing: 0.03rem;
    margin-left: 0.1rem;
  }
}

.deviceId {
  color: #9ac0d9;
  font-size: 0.14rem;
}
.endTime {
  color: #d8e1f3;
  font-size: 0.12rem;
}
</style>
