import request from '../../request';

const serviceName = '/smart-site/jiangsu/screen/deep';

/**
 * 高支模监测数据
 * @param {string} deviceId 设备ID
 */
export function getPitData(deviceId) {
  return request(`${serviceName}/deepCount`, {
    method: 'GET',
    body: {
      deviceId,
    },
  });
}

/**
 * 深基坑数据列表
 * @param {object} body
 * @param {number} body.current
 * @param {number} body.size
 * @param {number} body.idxProjectId 项目ID
 * @param {number} body.deviceId 项目ID
 */
export function getPitList(body) {
  return request(`${serviceName}/record`, {
    method: 'GET',
    body,
  });
}

/**
 * 深基坑趋势图数据
 * @param {object} body
 * @param {'SEVEN_DAY' | 'THIRTY_DAY'} body.days 日期筛选
 * @param {number} body.deviceId 设备ID
 * @param {number} body.projectId 项目ID
 * @param {'DFP_HORIZONTAL_DISPLACEMENT' | 'DFP_VERTICAL_SETTLEMENT' | 'DFP_POLE_INCLINATION '} body.sensor 传感器类型 DFP_POLE_INCLINATION:倾斜 DFP_VERTICAL_SETTLEMENT:垂直沉降 DFP_HORIZONTAL_DISPLACEMENT:水平位移
 */
export function getPitTrend(body) {
  return request(`${serviceName}/monitor/trend`, {
    method: 'GET',
    body,
  });
}

/**
 * 深基坑警报
 * @param {object} body
 * @param {number} body.projectId 项目ID
 * @param {number} body.deviceId 设备ID
 * @param {'SEVEN_DAY' | 'THIRTY_DAY'} body.days 最近天数
 */
export function getPitAlarm(body) {
  return request(`${serviceName}/statistics`, {
    method: 'POST',
    body,
  });
}
/**
 * 设备监测点枚举
 * @param {string} idxProjectId 项目ID
 * @returns
 */
export function getPitMonitoringType(idxProjectId) {
  return request(`${serviceName}/record/monitoringPoint`, {
    method: 'GET',
    body: { idxProjectId },
  });
}
