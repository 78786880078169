<template>
  <div class="grid grid-cols-2 gap-rem-16 overflow-hidden h-full">
    <div class="flex flex-col gap-rem-16">
      <div class="grid grid-cols-2 gap-rem-16 " style="height: 3rem;">
        <div class="col-span-1">
          <LayoutCard title="">
            <template #titleExtend>
              <a-select
                style="width: 1.5rem;margin-left: -0.16rem;"
                :options="deviceSelectOption"
                @change="handleDeviceChange"
                v-model="deviceId"
              ></a-select>
            </template>
            <template #action>
              <div :class="$style.detail" @click="handleShowDetail">详情</div>
            </template>
            <HighFormworkDataView />
          </LayoutCard>
        </div>
        <div class="col-span-1">
          <LayoutCard title="报警类型分析">
            <PieChart
              :chartData="alarmTypePieOption"
              :labelOption="alarmTypePieLebalOption"
              :legendWidth="100"
              left="10%"
              right="10%"
            />
          </LayoutCard>
        </div>
      </div>

      <div class="flex-1 overflow-hidden">
        <LayoutCard title="水平位移">
          <template #action>
            <range-picker
              @ok="v => handleSearchTime(v, '1')"
              v-if="weightTrendSelectValue === 'CUSTOM_DAY'"
            />
            <a-select
              :options="daySelectOption"
              v-model="weightTrendSelectValue"
              @change="() => handleSelectChange('1')"
            ></a-select>
          </template>
          <div class="h-full overflow-hidden" style="padding: 0.16rem;">
            <LineChart
              chart-width="100%"
              chart-height="100%"
              :isSmooth="false"
              symbol="round"
              chartRight="2%"
              :chart-data="weightTrendChartOption"
              :show-legend="true"
            />
          </div>
        </LayoutCard>
      </div>
    </div>

    <div class="flex flex-col gap-rem-16">
      <div>
        <LayoutCard title="深基坑平面图">
          <template #action>
            <ali-icon
              type="tc-icon-zhankai"
              @click="handleFullScreen"
            ></ali-icon>
          </template>
          <div :class="$style.planViewWrap">
            <PlanViewCanvas></PlanViewCanvas>
          </div>
        </LayoutCard>
      </div>

      <div class="grid grid-cols-2 gap-rem-16 flex-1">
        <div :class="['col-span-1']">
          <LayoutCard title="垂直沉降">
            <template #action>
              <range-picker
                @ok="v => handleSearchTime(v, '2')"
                v-if="horTrendSelectValue === 'CUSTOM_DAY'"
              />
              <a-select
                :options="daySelectOption"
                v-model="horTrendSelectValue"
                @change="() => handleSelectChange('2')"
              ></a-select>
            </template>
            <div class="h-full overflow-hidden" style="padding: 0.16rem;">
              <LineChart
                chart-width="100%"
                chart-height="100%"
                :isSmooth="false"
                symbol="round"
                chartRight="2%"
                :chart-data="horTrendChartOption"
                :show-legend="true"
              />
            </div>
          </LayoutCard>
        </div>
        <div :class="['col-span-1']">
          <LayoutCard title="倾斜">
            <template #action>
              <range-picker
                @ok="v => handleSearchTime(v, '3')"
                v-if="vertTrendSelectValue === 'CUSTOM_DAY'"
              />
              <a-select
                :options="daySelectOption"
                v-model="vertTrendSelectValue"
                @change="() => handleSelectChange('3')"
              ></a-select>
            </template>
            <div class="h-full overflow-hidden" style="padding: 0.16rem;">
              <LineChart
                chart-width="100%"
                chart-height="100%"
                :isSmooth="false"
                symbol="round"
                chartRight="2%"
                :chart-data="vertTrendChartOption"
                :show-legend="true"
              />
            </div>
          </LayoutCard>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';
import { LayoutCard, LayoutSelect } from '../../../layout';
import ScrollTable from '@/views/screen/components/scroll-table.vue';
import PitStatus from './components/pit-status.vue';
import LineChart from '@/views/screen/e-charts/line-chart';
import PieChart from '@/views/screen/e-charts/pie-chart.vue';
import { nowSize } from '@/utils/common';
import HighFormworkDataView from './components/data-view.vue';
import PlanViewCanvas from './components/canvas.vue';
import { Emitter } from '@/views/screen/components/screen-utils';
import { getDeviceLists } from '@/services/screen/jiangsu/vehicle';
import { deviceTypeEnum } from '@/enum/device';
import { pageTypeEnum } from '@/views/safety-device/component/safety-utils';
import moment from 'moment';
import { getPitAlarm, getPitTrend } from '@/services/screen/jiangsu/pit';
import { createModal } from '@triascloud/x-components';
import DataListModal from './components/data-list.vue';
import AliIcon from '@/components/ali-icon/index.vue';
import RangePicker from '@/views/screen/components/range-picker.vue';

@Component({
  components: {
    LayoutCard,
    LayoutSelect,
    ScrollTable,
    PitStatus,
    LineChart,
    PieChart,
    HighFormworkDataView,
    PlanViewCanvas,
    AliIcon,
    RangePicker,
  },
})
export default class HighFormworkIndex extends Vue {
  deviceGroup = deviceTypeEnum[pageTypeEnum.pitMonitor].deviceGroup;
  get projectId() {
    return this.$store.state.screen.projectId;
  }
  colorArray = ['#6BBDFF'];
  scrollTbaleColumns = [
    {
      title: '设备名称',
      key: 'index',
      width: '18%',
    },
    {
      title: '报警内容',
      key: 'index',
      width: '18%',
    },
    {
      title: '报警数值',
      key: 'index',
      width: '18%',
    },
    {
      title: '报警时间',
      key: 'date',
      width: '',
    },
  ];

  daySelectOption = [
    {
      label: '自定义',
      value: 'CUSTOM_DAY',
    },
    {
      label: this.$t('screen.sevenDay'),
      value: 'SEVEN_DAY',
    },
    {
      label: this.$t('screen.thirtyDay'),
      value: 'THIRTY_DAY',
    },
  ];

  scrollTbaleData = [];

  // 警报类型统计
  get alarmTypePieLebalOption() {
    const total = this.alarmTypePieOption.reduce((a, b) => a + b.value, 0);
    return {
      show: true,
      position: 'center',
      formatter: `{a|总}\n{b|${total}}`,
      rich: {
        a: {
          color: '#9AC0D9',
          fontSize: nowSize(12),
        },
        b: {
          color: '#fff',
          textShadowColor: '#7DB6FF',
          textShadowBlur: 8,
          fontSize: nowSize(18),
          padding: [nowSize(8), 0, 0, 0],
        },
      },
    };
  }
  get alarmTypePieOption() {
    const format = val => (+val > 0 ? +val : 0);
    return this.alarmData.map(item => ({
      value: format(item.count),
      name: item.typeName,
    }));
  }
  alarmSelectValue = 'SEVEN_DAY';
  alarmData = [];
  async getHeightFormworkAlarm() {
    const res = await getPitAlarm({
      days: this.alarmSelectValue,
      projectId: this.projectId,
      deviceId: this.deviceId,
    });
    this.alarmData = res;
  }

  // 设备相关
  deviceId = '';
  deviceList = [];
  handleDeviceChange() {
    Emitter.$emit('deviceChange', this.deviceId);
  }
  get deviceSelectOption() {
    return this.deviceList.map(item => ({
      label: item.deviceName,
      value: item.pkId,
    }));
  }
  async getDeviceList() {
    this.deviceList = await getDeviceLists({
      deviceGroup: this.deviceGroup.toLocaleUpperCase(),
      idxProjectId: this.projectId,
    });
    this.deviceId = this.deviceList[0].pkId;
    Emitter.$emit('deviceChange', this.deviceId);
  }

  // 水平位移趋势图
  weightTrendSelectValue = 'SEVEN_DAY';
  weightTrendData = {
    dataList: [],
    times: [],
  };
  get weightTrendChartOption() {
    const { times, dataList } = this.weightTrendData;
    return [
      {
        time: times.map(e => moment(e).valueOf()),
        data: dataList?.map(item => ({
          name: item.tag,
          data: item.values.map(value => ({
            value: +value === -1 ? 0 : value,
          })),
        })),
      },
    ];
  }
  async getWeightTrendChart(startTime = undefined, endTime = undefined) {
    this.weightTrendData = await getPitTrend({
      days: this.weightTrendSelectValue,
      type: 'DFP_HORIZONTAL_DISPLACEMENT',
      projectId: this.projectId,
      deviceId: this.deviceId,
      startTime,
      endTime,
    });
  }

  // 垂直沉降趋势图
  horTrendSelectValue = 'SEVEN_DAY';
  horTrendData = {
    dataList: [],
    times: [],
  };
  get horTrendChartOption() {
    const { times, dataList } = this.horTrendData;
    return [
      {
        time: times.map(e => moment(e).valueOf()),
        data: dataList?.map(item => ({
          name: item.tag,
          data: item.values.map(value => ({
            value: +value === -1 ? 0 : value,
          })),
        })),
      },
    ];
  }
  async getHorTrendChart(startTime = undefined, endTime = undefined) {
    this.horTrendData = await getPitTrend({
      days: this.horTrendSelectValue,
      type: 'DFP_VERTICAL_SETTLEMENT',
      projectId: this.projectId,
      deviceId: this.deviceId,
      startTime,
      endTime,
    });
  }

  // 倾斜趋势图
  vertTrendSelectValue = 'SEVEN_DAY';
  vertTrendData = {
    dataList: [],
    times: [],
  };
  get vertTrendChartOption() {
    const { times, dataList } = this.vertTrendData;
    return [
      {
        time: times.map(e => moment(e).valueOf()),
        data: dataList?.map(item => ({
          name: item.tag,
          data: item.values.map(value => ({
            value: +value === -1 ? 0 : value,
          })),
        })),
      },
    ];
  }
  async getVertTrendChart(startTime = undefined, endTime = undefined) {
    this.vertTrendData = await getPitTrend({
      days: this.vertTrendSelectValue,
      type: 'DFP_POLE_INCLINATION',
      projectId: this.projectId,
      deviceId: this.deviceId,
      startTime,
      endTime,
    });
  }

  handleSelectChange(type) {
    if (type === '1') {
      this.weightTrendSelectValue !== 'CUSTOM_DAY' &&
        this.getWeightTrendChart();
    } else if (type === '2') {
      this.horTrendSelectValue !== 'CUSTOM_DAY' && this.getHorTrendChart();
    } else if (type === '3') {
      this.vertTrendSelectValue !== 'CUSTOM_DAY' && this.getVertTrendChart();
    }
  }

  // 时间筛选
  handleSearchTime(time, type) {
    const startTime = this.$moment(time[0]).valueOf();
    const endTime = this.$moment(time[1]).valueOf();
    if (type === '1') {
      this.getWeightTrendChart(startTime, endTime);
    } else if (type === '2') {
      this.getHorTrendChart(startTime, endTime);
    } else if (type === '3') {
      this.getVertTrendChart(startTime, endTime);
    }
  }

  // 详情
  handleShowDetail() {
    createModal(
      () => (
        <DataListModal
          projectId={this.projectId}
          deviceOption={this.deviceSelectOption}
          deviceId={this.deviceId}
        />
      ),
      {
        title: '深基坑监测数据',
        className: 'screen-modal',
        width: '80vw',
      },
    );
  }

  initData() {
    this.getWeightTrendChart();
    this.getHorTrendChart();
    this.getVertTrendChart();
    this.getHeightFormworkAlarm();
  }

  handleFullScreen() {
    createModal(
      () => (
        <div
          class={'w-full flex justify-center items-center'}
          style={'aspect-ratio: 16/9;'}
        >
          <PlanViewCanvas isModal />
        </div>
      ),
      {
        width: '80vw',
        wrapClassName: 'konva-wrapper',
      },
    );
  }

  async created() {
    Emitter.$on('deviceChange', this.initData);
    await this.getDeviceList();
  }

  beforeDestroy() {
    Emitter.$off('deviceChange', this.initData);
  }
}
</script>

<style lang="less" module>
.planViewWrap {
  height: 45vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.detail {
  color: #37d7db;
  font-size: 0.14rem;
  cursor: pointer;
  user-select: none;
}
</style>
